import React from "react";
import Style from "../Styles/Style";
import StyleFooter from "../Styles/StyleFooter";
import StyleFooterTilt from "../Styles/StyleFooterTilt";
import StyleFooterTriangle from "../Styles/StyleFooterTriangle";
import StyleTilt from "../Styles/StyleTilt";
import StyleTriangle from "../Styles/StyleTriangle";

const Section = (props) => {
  return (
    <div
      className={`section section-${props.size} ${props.color}-bg ${
        props.zIndex ? "z-index" : null
      }`}
      id={props.id}
    >
      <div className="container-lg">{props.children}</div>
      {props.shape === "normal" ? (
        !props.foot ? (
          <Style add={props.add} white={props.white} />
        ) : (
          <StyleFooter />
        )
      ) : props.shape === "tilt" ? (
        !props.foot ? (
          <StyleTilt add={props.add} white={props.white} left={props.left} />
        ) : (
          <StyleFooterTilt left={props.left} />
        )
      ) : !props.foot ? (
        <StyleTriangle add={props.add} white={props.white} />
      ) : (
        <StyleFooterTriangle />
      )}
    </div>
  );
};

export default Section;
