import React from "react";

const ColorBlocks = (props) => {
  return (
    <div className="col-12 pb-4 mx-auto col-md-6 col-lg-4 col-block col-md-3 col-block d-flex flex-column">
      <div className="col py-4 px-4 border has-touch-bar h-100 mt-4 mx-auto">
        <div className="col-inner mx-2">
          <h4 className="my-auto mb-3 text-upper">{props.title}</h4>
          <div className="mb-0 col-content ">
            <p>{props.children}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorBlocks;
