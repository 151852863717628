import React from "react";
import Section from "../../HOC/Section";

import arrow from "../../assets/images/arrow.svg";
import bill from "../../assets/images/bill.svg";
import c02 from "../../assets/images/c02.svg";
import money from "../../assets/images/money.svg";
import value from "../../assets/images/value.svg";

const IconsFeatures = (props) => {
  return (
    <Section color="white" size="sm" white={false} shape="tilt">
      <div style={{ paddingBottom: "80px" }}>
        <div className="section-title text-center pb-4 text-upper">
          <h2>
            Unlock <span>Sustainable</span> Success
          </h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-mb-10">
            <h5 className="pb-10 text-center text-primary">
              Empower Your Business with Steadfast Solar Solutions and Maximize
              Savings
            </h5>
            <p className="pb-40 text-center">
              Discover a brighter future for your business with Steadfast
              Solar's cutting-edge commercial solar solutions. Harness the power
              of renewable energy to drive your organization towards
              sustainability, while simultaneously enjoying significant cost
              savings.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-2 text-center mb-20">
            <div className="aio-icon">
              <img src={money} alt={money} />
            </div>
            <div className="aio-title">
              <h4 className="text-upper">SAVE MONEY</h4>
            </div>
            <div className="aio-description">
              <p>Save money on your energy bill</p>
            </div>
          </div>
          <div className="col-12 col-md-2 text-center mb-20">
            <div className="aio-icon">
              <img src={value} alt={value} />
            </div>
            <div className="aio-title">
              <h4 className="text-upper">ADD VALUE</h4>
            </div>
            <div className="aio-description">
              <p>Add value to your business</p>
            </div>
          </div>
          <div className="col-12 col-md-2 text-center mb-20">
            <div className="aio-icon">
              <img src={arrow} alt={arrow} />
            </div>
            <div className="aio-title">
              <h4 className="text-upper">REDUCE RELIANCE</h4>
            </div>
            <div className="aio-description">
              <p>
                Reduce your reliance on the rising cost of power from the grid
              </p>
            </div>
          </div>
          <div className="col-12 col-md-2 text-center mb-20">
            <div className="aio-icon">
              <img src={bill} alt={bill} />
            </div>
            <div className="aio-title">
              <h4 className="text-upper">GET PAID</h4>
            </div>
            <div className="aio-description">
              <p>
                Get paid for any excess power your export back to the grid (if
                eligible)
              </p>
            </div>
          </div>
          <div className="col-12 col-md-2 text-center mb-20">
            <div className="aio-icon">
              <img src={c02} alt={c02} />
            </div>
            <div className="aio-title">
              <h4 className="text-upper">Reduce Emissions</h4>
            </div>
            <div className="aio-description">
              <p>Reduce your carbon emissions</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default IconsFeatures;
