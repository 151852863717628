import { B2T, MAIL, MAILERROR, NAVBAR, TOGGLE } from "./constants";

const initialStateBase = {
  toggle: false,
  show: false,
  navbar: undefined,
  sent: false,
  sentError: false,
};

export const BaseReducer = (state = initialStateBase, action = {}) => {
  switch (action.type) {
    case NAVBAR:
      return Object.assign({}, state, { navbar: action.payload });
    case MAIL:
      return Object.assign({}, state, { sent: action.payload });
    case MAILERROR:
      return Object.assign({}, state, { sentError: action.payload });
    case TOGGLE:
      return Object.assign({}, state, { toggle: action.payload });
    case B2T:
      return Object.assign({}, state, { show: action.payload });
    default:
      return state;
  }
};
