import React from "react";

import { Link } from "react-router-dom";
const FeaturePanel = (props) => {
  return (
    <div className="features-card">
      <div className="features-card-img">
        <img src={props.img} alt={props.img} className="img-fluid" />
        <Link to={props.link}>
          <i className="lni lni-lg lni-link" style={{ fontWeight: 700 }}></i>
        </Link>
      </div>
      <div className="features-card-text">
        <Link to={props.link}>
          <h5>{props.children}</h5>
        </Link>
      </div>
    </div>
  );
};

export default FeaturePanel;
