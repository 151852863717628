import React from "react";
import { Link } from "react-router-dom";
import Style from "../../Styles/Style";
import StyleFooter from "../../Styles/StyleFooter";
import StyleFooterTilt from "../../Styles/StyleFooterTilt";
import StyleFooterTriangle from "../../Styles/StyleFooterTriangle";
import StyleTilt from "../../Styles/StyleTilt";
import StyleTriangle from "../../Styles/StyleTriangle";

const Advert = (props) => {
  return (
    <div className="section section-md add intro-overlay-7">
      <div className="container-lg z-index">
        <h1 className="mb-4 text-upper">{props.title}</h1>
        {props.children}
        <div className="mt-30">
          <Link
            to="/contactus"
            className="btn status-btn secondary-btn-outline btn-hover rounded-20 px-5"
          >
            Get a Quote
          </Link>
        </div>
      </div>
      {props.shape === "normal" ? (
        !props.foot ? (
          <Style add={props.add} white={props.white} />
        ) : (
          <StyleFooter />
        )
      ) : props.shape === "tilt" ? (
        !props.foot ? (
          <StyleTilt add={props.add} white={props.white} left={props.left} />
        ) : (
          <StyleFooterTilt left={props.left} />
        )
      ) : !props.foot ? (
        <StyleTriangle add={props.add} white={props.white} />
      ) : (
        <StyleFooterTriangle />
      )}
    </div>
  );
};

export default Advert;
