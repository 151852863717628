import React from "react";
import Section from "../../../HOC/Section";

import I3 from "../../../assets/images/AI/drone.jpg";
import I1 from "../../../assets/images/AI/woman.jpg";
import I2 from "../../../assets/images/apiscp_1.png";

const BodyContent = (props) => {
  return (
    <Section shape="tilt" left={true} white={false} size="lg" color="white">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-6 mb-5">
          <h2 className="title mb-3 text-upper">
            Builders <span>Range</span>
          </h2>
          <p>
            Steadfast Solar work with you to decide what system will be right
            for your build. When arranging your solar system, it’s important to
            work with an expert who is up to date with technological changes and
            who understands issues such as the energy rating requirements for
            new homes.
          </p>
          <br />
          <p>
            Steadfast Solar are the experts in this field. We will make sure you
            are ticking all the right boxes when it comes to regulatory
            requirements and more importantly, we make sure you get quality
            solutions at a great price.
          </p>
        </div>
        <div className="col-12 col-md-6 mb-5">
          <div className="border-image img-border-invert img-shadow">
            <img src={I1} alt={I1} className="img-fluid" />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-5">
          {/* <div className="border-image img-border img-shadow-invert"> */}
          <img src={I2} alt={I2} className="img-fluid" />
          {/* </div> */}
        </div>
        <div className="col-12 col-md-6 mb-5">
          <p>
            Steadfast Solar work with you to decide what system will be right
            for your build. When arranging your solar system, it’s important to
            work with an expert who is up to date with technological changes and
            who understands issues such as the energy rating requirements for
            new homes.
          </p>
          <p>
            At Steadfast Solar we manage all aspects of the process including:
          </p>
          <ul>
            <li>Solar design</li>
            <li>Solar contribution calculations and reports</li>
            <li>SA Power Networks applications and approvals</li>
            <li>Installation</li>
            <li>Small Scale Technology Certificate creation</li>
            <li>
              On-going Support to the Builder & it’s Client for all Solar
              Related queries and system support
            </li>
          </ul>
        </div>

        <div className="col-12 col-md-6 mb-5">
          <p>
            At Steadfast Solar we discuss a solar package with the Builder to
            ensure that the system is functional to the build.
          </p>
          <br />
          <p>
            Whilst we’re working to meet the Builder’s needs, we also work with
            the home owner to discuss the solar system being installed, giving
            them an understanding of the expected electricity savings which it
            will generate. At this time also, we offer additional options which
            the Client may request at that time.
          </p>
        </div>
        <div className="col-12 col-md-6 mb-5">
          <div className="border-image img-border-invert img-shadow">
            <img src={I3} alt={I3} className="img-fluid" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default BodyContent;
