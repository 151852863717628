import React from "react";
import Style from "../../Styles/Style";
const Intro = (props) => {
  return (
    <div className="intro-pages intro-overlay-6" id={props.id}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-8">
            <div
              className="position-relative z-index"
              style={{ paddingTop: "80px" }}
            >
              <h1 className="welcome" style={{ zIndex: -1, top: "50px" }}>
                {props.title}
              </h1>
              <h1 className="h1 z-index">{props.title}</h1>
              <h2 className="text-white z-index">{props.subtitle}</h2>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4"></div>
        </div>
      </div>
      <Style white={props.color === "white" ? true : false} />
    </div>
  );
};

export default Intro;
