import React from "react";

const Icons = (props) => {
  return (
    <div
      className={`aio-icon-component ${
        props.right === true ? "text-right" : "text-left"
      }`}
    >
      {props.right === true ? (
        <div
          className={`aio-icon-box ${
            props.right === true ? "right" : "left"
          }-icon`}
        >
          <div className="aio-ibd-block">
            <div className="aio-icon-description">
              <h5>{props.text}</h5>
            </div>
          </div>
          <div
            className={`aio-icon-${props.right === true ? "right" : "left"}`}
          >
            <div className="aio-icon aio-icon-circle">
              <i className={`lni lni-${props.icon}`}></i>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`aio-icon-box ${
            props.right === true ? "right" : "left"
          }-icon`}
        >
          <div
            className={`aio-icon-${props.right === true ? "right" : "left"}`}
          >
            <div className="aio-icon aio-icon-circle">
              <i className={`lni lni-${props.icon}`}></i>
            </div>
          </div>

          <div className="aio-ibd-block">
            <div className="aio-icon-description">
              <h5>{props.text}</h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Icons;
