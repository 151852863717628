import React, { useEffect, useState } from "react";
import { animateScroll as scroller } from "react-scroll";
import Advert from "../../Components/Advert/Advert";
import B2t from "../../Components/B2t/B2t";
import Intro from "../../Components/Intro/Intro";
import Products from "../../Components/Products/Products";
import Body from "./Body/Body";
const SolarPartner = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div style={{overflow: 'hidden'}}>
      <B2t show={props.show} />
      <Intro title="ACCREDITED SOLAR PARTNERS" subtitle="" id="home" />
      <Body />
      <Advert
        title="UTILISE OUR EXPERTISE AND CREDENTIALS TO MAKE MONEY"
        shape="normal"
        foot={false}
        white={true}
      >
        <p>
          Steadfast Solar is the market leader in South Australia, delivering
          Solar solutions that are practical and cost-effective
        </p>
        <p>
          The Steadfast Solar Accredited Solar Partners (SSASP) program allows
          businesses and/or individuals to promote Solar Systems to their
          existing clients, to make money for themselves.
        </p>
        <p>
          Becoming an Accredited Solar Partner with us is a great way for you to
          generate extra revenue.
        </p>
      </Advert>
      <Products color="white" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default SolarPartner;
