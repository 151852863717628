import React from "react";
import { Link } from "react-router-dom";

import * as Scroll from "react-scroll";

import Logo from "../../assets/images/logo.png";

const Links = Scroll.Link;

const Footer = (props) => {
  const NavLinks = () => {
    if (
      !document.location.href.endsWith("contactus") &&
      !document.location.href.endsWith("about") &&
      !document.location.href.endsWith("builders") &&
      !document.location.href.endsWith("accredited-solar-partners") &&
      !document.location.href.endsWith("commercial-solar") &&
      !document.location.href.endsWith("residential-solar") &&
      !document.location.href.endsWith("denied")
    ) {
      return (
        <>
          <Links
            to="home"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            Home
          </Links>
          <Links
            to="services"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            Services
          </Links>
          <Link to="/residential-solar" className="nav-link">
            Residential Solar
          </Link>
          <Link to="/commercial-solar" className="nav-link">
            Commercial Solar
          </Link>
          <Link to="/builders" className="nav-link">
            Builders
          </Link>
          <Link to="/accredited-solar-partners" className="nav-link">
            Accredited Solar Partners
          </Link>
          <Link to="/about" className="nav-link">
            About
          </Link>
          <Link to="/contactus" className="nav-link">
            Contact Us
          </Link>
        </>
      );
    } else {
      return (
        <>
          <Link className="nav-link" to="/">
            Home
          </Link>
          <Link to="/residential-solar" className="nav-link">
            Residential Solar
          </Link>
          <Link to="/commercial-solar" className="nav-link">
            Commercial Solar
          </Link>
          <Link to="/builders" className="nav-link">
            Builders
          </Link>
          <Link to="/accredited-solar-partners" className="nav-link">
            Accredited Solar Partners
          </Link>
          <Link to="/about" className="nav-link">
            About
          </Link>
          <Link to="/contactus" className="nav-link">
            Contact Us
          </Link>
        </>
      );
    }
  };
  return (
    <div className="section section-ms pb-0 footer-bg" id="footer">
      <div className="container-lg px-4 px-md-5">
        <div className="row align-items-stretch justify-content-between">
          <div className="col-sm-12 col-md-4 col-lg-3 mb-4">
            <img
              src={Logo}
              alt={Logo}
              style={{ height: "60px", filter: "brightness(1.05)" }}
            />
            <hr />
            <p>
              Steadfast Solar is a South Australian locally owned and family run
              business, dedicated to bringing our customers the very best in
              Solar solutions.
            </p>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4 mb-4">
            <h3 className="text-white mt-30">Contact Us</h3>
            <hr />
            <ul className="list-group">
              <li className="list-group-item d-flex align-items-center border-0 bg-none text-white">
                <p>
                  <span className="text-bold me-3">
                    <i className="lni lni-map me-2"></i>Address:
                  </span>{" "}
                  Level 1, 160 Sir Donald Bradman Drive HILTON SA 5033
                </p>
              </li>
              <li className="list-group-item d-flex align-items-center border-0 bg-none text-white">
                <p>
                  <span className="text-bold me-3">
                    <i className="lni lni-mobile me-2"></i>Phone:
                  </span>{" "}
                  08 8257 9199
                </p>
              </li>
              <li className="list-group-item d-flex align-items-center border-0 bg-none text-white">
                <p>
                  <span className="text-bold me-3">
                    <i className="lni lni-envelope me-2"></i>Email:
                  </span>
                  <a
                    href="mailto:info@Steadfastsolar.com.au"
                    className="text-secondary"
                  >
                    info@steadfastsolar.com.au
                  </a>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-2 col-lg-3">
            <h3 className="text-white mt-30">Navigation</h3>
            <hr />
            <div className="navbar-nav">{<NavLinks />}</div>
          </div>
        </div>
        <div className="text-white text-center pt-4 mb-0 pb-0 quote">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              ISAIAH 26:3-4; You will keep in perfect peace those whose minds
              are Steadfast, because they trust in you. Trust in the LORD
              forever,for the LORD, the LORD himself, is the Rock eternal.
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        Copyright © 2023 Steadfast solar . All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
